<template>
  <div :key="$route.params.name">
    <canvas id="doge-canvas" />
    <router-view :key="$route.params.name" />
    <div class="row channel__row">
      <div class="column1">
        <div
          id="player"
          :key="$route.params.name"
          class="brimePlayer"
        >
          <video
            id="stream-video"
            playsinline
          />
        </div>
        <div>
          <div id="channel">
            <a
              @click="scrollPastVideo"
            ><b-avatar
              v-if="channelOwner"
              size="50"
              :src="`https://content.brimecdn.com/cdn-cgi/image/width=80,quality=100/brime/user/${channelOwner}/avatar`"
              variant="light-primary"
              class="badge-minimal hide-on-mobile"
            /></a>
            <span
              style="
                vertical-align: top;
                color: white;
              "
              class="channelName"
            >
              <img
                v-if="verified"
                src="https://beta.brimelive.com/brime_verified.png"
                style="padding-left: 5px"
              >
              {{ channel }}
              <span style="vertical-align: top; opacity: 0.5">
                |<span
                  id="viewcount"
                  style="vertical-align: center; font-size: 15px"
                  class="viewcount"
                /></span></span>
            <div
              class="streamCategory"
              style="margin-left: 55px; padding-top: -10px"
            >
              <router-link :to="'/category/' + category.slug">{{
                category.name
              }}</router-link>
              &bull;
              <span class="streamTitle">
                {{ title }}
              </span>
            </div>
          </div>
          <div class="actionButtons">
            <b-button-group
              size="sm"
              class="float-right"
            >
              <b-button
                v-if="following === false && !isChannelOwner"
                variant="outline-primary"
                size="sm"
                @click="follow"
              >
                <feather-icon
                  icon="HeartIcon"
                  class="mr-50 pt-0 pb-0"
                />
                <span
                  class="align-middle"
                  style="line-height: 14px"
                >Follow</span>
              </b-button>
              <b-button
                v-if="following === true && !isChannelOwner"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                title="following"
                variant="primary"
                class="btn-icon"
                @click="unfollow"
              >
                <feather-icon
                  icon="HeartIcon"
                  class="fill"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="hide-on-mobile"
                disabled
              >
                <span id="followCount">{{ followers }}</span> Followers
              </b-button> </b-button-group><b-button
              v-if="monetized && !isSubscribed && !isChannelOwner"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="primary"
              style="line-height: 14px"
              class="float-right"
              @click="openCheckout()"
            >Subscribe
            </b-button>
            <b-button
              v-if="!isChannelOwner && live && userData && viewerIsLive"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="primary"
              class="float-right hide-on-mobile"
              @click="sendRaid"
            >
              <span
                class="align-middle"
                style="line-height: 14px"
              >Raid</span>
            </b-button>
            <!-- We keep this here to keep the modal, idk how this shit works. (Also for mobile compatibility.) -->
            <b-button
              v-if="live"
              id="clipItButton"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.clipModal
              size="sm"
              variant="primary"
              class="float-right"
              hidden
              @click="clipIt"
            >
              <feather-icon
                icon="ScissorsIcon"
                class="mr-50 hide-on-mobile"
              /><span
                class="align-middle"
                style="line-height: 14px"
              >Create Clip</span>
            </b-button>
          </div>
        </div>
        <div
          id="tabSection"
          class="tabSection"
        >
          <b-tabs>
            <b-tab title="Socials">
              <b-card-text>
                <section
                  id="card-images"
                  class="vodSection"
                >
                  <b-row>
                    <h4 style="margin-left: 15px; font-size: 14px">
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="24"
                      /> This
                      channel doesn't currently have any socials added.
                    </h4>
                    <br><br><br>
                  </b-row>
                </section>
              </b-card-text>
            </b-tab>
            <b-tab
              active
              title="Clips"
            >
              <b-card-text>
                <section
                  id="card-images"
                  class="vodSection"
                >
                  <b-row>
                    <b-col
                      v-for="item in channelClips.clips"
                      :key="item._id"
                      md="5"
                      lg="4"
                      xl="3"
                    ><b-card
                      v-b-modal.clipPlayerModal
                      class="mb-3"
                      @click="sendInfo(item)"
                    >
                      <div class="vod__image">
                        <img
                          v-if="item.clipThumbnailUrl"
                          width="100%"
                          :src="item.clipThumbnailUrl"
                        >
                        <img
                          v-if="!item.clipThumbnailUrl"
                          width="100%"
                          src="https://content.brimecdn.com/brime/assets/missing_thumbnail.png"
                        >
                        <div class="vod__duration">
                          {{
                            getDurationClip(
                              item.sectionStart,
                              item.sectionEnd
                            )
                          }}
                        </div>
                      </div>
                      <div
                        id="vodTitle"
                        class="vod__title"
                      >
                        {{ item.stream.title }}
                      </div>
                      <div class="vod__category">
                        {{ item.stream.category.name }}
                      </div>
                      <div class="vod__date">
                        {{ getAgoClip(item.sectionStart) }}
                      </div>
                      <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
                    </b-card></b-col>
                  </b-row>
                </section>
              </b-card-text>
            </b-tab>
            <b-tab title="Vods">
              <b-card-text>
                <section
                  id="card-images"
                  class="vodSection"
                >
                  <b-row>
                    <b-col
                      v-for="item in channelVods.vods"
                      :key="item._id"
                      md="5"
                      lg="4"
                      xl="3"
                    ><router-link :to="`/videos/${item._id}`">
                      <b-card class="mb-3">
                        <div class="vod__image">
                          <img
                            width="100%"
                            :src="item.vodThumbnailUrl.replace('https://content.brimecdn.com/brime-vods/', 'https://vod.brimecdn.com/n/idmrpurnoj34/b/brime-vods/o/').replace('content.brimecdn.com', 'hls.brimecdn.com')"
                          >
                          <div class="vod__duration">
                            {{ getDuration(item.startDate, item.endDate) }}
                          </div>
                        </div>
                        <div
                          id="vodTitle"
                          class="vod__title"
                        >
                          {{ item.stream.title }}
                        </div>
                        <div class="vod__category">
                          {{ item.stream.category.name }}
                        </div>
                        <div class="vod__date">
                          {{ getAgo(item.startDate) }}
                        </div>
                        <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :href="item.strm"
          >
            Watch
          </b-button> -->
                      </b-card></router-link></b-col>
                  </b-row>
                </section>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="column chat__column">
        <ul
          id="chat-thread"
          class="chat-thread"
        />
        <b-form
          id="chat-form"
          method="post"
          @keydown.enter.prevent="
            (messageToSend = message), (message = ''), sendMessage()
          "
        >
          <b-input-group class="chatInput">
            <b-form-input
              id="message"
              ref="message22"
              v-model="message"
              type="text"
              class="form-control"
              name="message"
              placeholder="Send a message"
              autocomplete="off"
              autofocus
              @blur="clearPlaceholder"
              @keyup="autoSuggest"
              @keydown.tab.prevent="fillAutocomplete"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                class="emoji-picker-trigger"
                style="
                  margin-left: 0;
                  margin-right: 0;
                  padding-left: 10px;
                  padding-right: 10px;
                "
                @click.prevent="focusInput()"
              >
                <feather-icon
                  icon="SmileIcon"
                  class="mr-0 pt-0 pb-0"
                />
              </b-button>
            </b-input-group-append>
            <button hidden>
              Send
            </button>
          </b-input-group>
        </b-form>
      </div>
      <!-- <form @submit.prevent="sendMessage" id="chat-form" method="post"><input type="text" class="form-control" name="message" id="message" v-model="message" placeholder="Send a message" autocomplete="off"><button hidden>Send</button></form> -->
    </div>
    <b-modal
      id="subscribe"
      centered
      :title="'Subscribe to ' + channel"
      ok-only
      ok-title="Cancel"
      size="lg"
      button-size="sm"
    >
      <Sub />
    </b-modal>
    <b-modal
      id="clipModal"
      ok-only
      ok-title="Close"
      centered
      size="lg"
      title="Brime Clip ™"
      @hide="(clipDone = false), (clipVideoUrl = ''), (clipID = '')"
    >
      <b-card-text><img
                     v-if="clipGenerating === true"
                     class="loadingClip"
                     src="https://us-central-1-s3.netdepot.com/brime/ShareX/2021/04/6069749866d36392271037.gif"
                   >
        <vue-plyr
          v-if="clipGenerating === false"
          :options="options"
        >
          <video
            controls
            crossorigin
            playsinline
            autoplay
          >
            <source
              size="1080"
              :src="`https://vod.brimecdn.com/n/ax9rag1oiyr8/b/brime/o/clip/${streamData._id}/${clipID}`"
              type="video/mp4"
            >
          </video>
        </vue-plyr>
        <br v-if="clipGenerating === false">
        <b-button
          v-if="clipGenerating === false"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          size="sm"
          variant="primary"
          :href="twitterShare + clipID"
          target="_blank"
        >
          Share On Twitter
        </b-button>
      </b-card-text>
    </b-modal>
    <b-modal
      id="clipPlayerModal"
      ok-only
      ok-title="Close"
      button-size="sm"
      centered
      size="lg"
      title="Brime Clip ™"
      @hide="(selectedClip = ''), killClipPlayer()"
    ><vue-plyr :options="options">
       <video
         controls
         crossorigin
         playsinline
         autoplay
       >
         <source
           size="1080"
           :src="`https://vod.brimecdn.com/n/ax9rag1oiyr8/b/brime/o/clip/${selectedClip.channelID}/${selectedClip._id}`"
           type="video/mp4"
         >
       </video> </vue-plyr><br>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        variant="primary"
        :href="twitterShare + selectedClip._id"
        target="_blank"
      >
        Share On Twitter
      </b-button>
      <b-button
        v-if="isChannelOwner"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="float-right"
        size="sm"
        variant="danger"
        target="_blank"
        @click="deleteClip(selectedClip._id)"
      ><feather-icon
        icon="TrashIcon"
        class="mr-40 pt-0 pb-0"
      />
        Delete
      </b-button><br><br>
      <b-form-input
        id="title"
        v-model="selectedClip.url"
        rows="1"
        placeholder="owning Puppet in Valorant!"
        maxlength="50"
        style="max-width: 30em;"
      />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */

// import Hls from 'hls.js'
import * as Ably from 'ably'
import {
  BButton,
  BAvatar,
  BModal,
  BCardText,
  BButtonGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BForm,
  BTabs,
  BTab,
  BRow,
  BCard,
  BCol,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { EmojiButton } from '@joeattardi/emoji-button'
import Vue2Filters from 'vue2-filters'
import Sub from './Modules/Sub.vue'

function debounce(func, timeout = 250) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post.Authorization = `Bearer ${window.brime.getAccessToken()}`

const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
  // eslint-disable-next-line
    const r = (Math.random() * 16) | 0;
  // eslint-disable-next-line
    const v = c == "x" ? r : (r & 0x3) | 0x8;

  return v.toString(16)
})

const getUserData = () => {
  function parseJwt(token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    )
    return JSON.parse(jsonPayload)
  }
  if (localStorage.getItem('accessToken')) {
    const userData = parseJwt(localStorage.getItem('accessToken'))
    userData.role = 'user'
    userData.avatar = 'https://i.ibb.co/fMF9pQJ/Doge-Tri-Poloski.png'
    userData.ability = [{ action: 'manage', subject: 'all' }]
    return userData
  }
  return {
    userData: {},
    avatarText: {},
  }
}

const getUUID = () => {
  let uuid = localStorage.getItem('brime_uuid')

  if (!uuid) {
    uuid = uuidv4()
    localStorage.setItem('brime_uuid', uuid)
  }

  return uuid
}

const userData = getUserData()

export default {
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  components: {
    BButtonGroup,
    BAvatar,
    BButton,
    BModal,
    BCardText,
    Sub,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BForm,
    BTabs,
    BTab,
    BRow,
    BCard,
    BCol,
  },
  mixins: [Vue2Filters.mixin],
  props: {
    name: {
      type: String,
      default: 'CHANNEL NOT FOUND',
    },
  },
  data() {
    return {
      suggestions: [],
      channelChat: null,
      options: {
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'download',
          'mute',
          'volume',
          'settings',
          'fullscreen',
        ],
      },
      gameurl: 'https://www.igdb.com/games/',
      following: false,
      followers: null,
      twitterShare: 'http://twitter.com/share?url=https://clips.brimelive.com/',
      title: '',
      category: '',
      clipGenerating: false,
      clipDone: false,
      channelOwner: '',
      isChannelOwner: null,
      streamData: { _id: '' },
      channelVods: [],
      userData: { username: '' },
      channelClips: [],
      clipVideoUrl: '',
      clips: '',
      clipChannel: '',
      musicData: null,
      isLive: false,
      clipChannelStuff: '',
      selectedClip: '',
      isSubscribed: true,
      clipID: null,
      live: false,
      chatUsers: null,
      testURL: 'https://players.akamai.com/players/hlsjs?streamUrl=',
      monetized: null,
      verified: false,
      brimeLLSuppported: true,
      resOptions: [],
      chat: [],
      message: '',
      messageToSend: '',
      channel: '',
      stream: {},
      chaturl: { url: 'https://beta.brimelive.com:8080/' },
      viewerIsLive: false,
    }
  },
  watch: {
    // TODO what is this
    // eslint-disable-next-line
    '$route.params.name': function () {
      this.$router.go()
    },
  },
  async mounted() {
    this.userData = await window.brime.getUser()

    if (this.userData) {
      const selfChannelData = await window.brime.getChannel()

      this.viewerIsLive = selfChannelData.isLive
    }

    // Init resize debounce
    this.resizeHandler = debounce(() => {
      document.querySelector('.chat__column').style = `--headerHeight: ${
        document.querySelector('.header-navbar').clientHeight
      }px;`
    })

    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)

    // Chargebee
    this.cbInstance = window.Chargebee.init({ site: 'brime' })

    // Blur buttons ?
    document.addEventListener('click', () => {
      if (document.activeElement instanceof HTMLButtonElement) {
        document.activeElement.blur()
      }
    })

    // Emoji picker
    const picker = new EmojiButton(
      {
        position: {
          bottom: '0',
          right: '0',
        },
        categories: ['custom'],
        initialCategory: 'custom',
        showCategoryButtons: false,
        styleProperties: {
          '--font': 'Courier New',
          '--background-color': '#111727',
          '--text-color': 'white',
          '--secondary-text-color': 'white',
          '--category-button-color': 'linear-gradient(90deg,#833ab4,#fd1d1d)',
        },
        i18n: {
          search: 'Search emojis...',
          categories: {
            custom: 'Brime Global',
          },
          notFound: 'No emojis found',
        },
        custom: [
          {
            name: 'BrimeTime ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607bb7a0d2595193fc085ff9/1x',
          },
          {
            name: 'Doge ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607bb07bd2595193fc085ff6/1x',
          },
          {
            name: 'brimePop ',
            emoji:
              'https://content.brimecdn.com/brime/emote/60adf0acb02edcd85e096079/1x',
          },
          {
            name: 'Nashed ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607f931013b66ed6d03de8cc/1x',
          },
          {
            name: 'OBSSweat ',
            emoji:
              'https://content.brimecdn.com/brime/emote/6098e55d791971b70b1a3d05/1x',
          },
          {
            name: 'brimeDoge ',
            emoji:
              'https://content.brimecdn.com/brime/emote/60aeb56847e3a5efb5d862b9/1x',
          },
          {
            name: 'Pog ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607ba48ad2595193fc085ff4/1x',
          },
          {
            name: 'KEKW ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607ba5dcd2595193fc085ff5/1x',
          },
          {
            name: 'FeelsBrimeProMan ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607fa67d13b66ed6d03de93e/1x',
          },
          {
            name: 'PauseChamp ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607bb721d2595193fc085ff8/1x',
          },
          {
            name: 'Jebaited ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607bb52fd2595193fc085ff7/1x',
          },
          {
            name: 'monkaW ',
            emoji:
              'https://content.brimecdn.com/brime/emote/607bb833d2595193fc085ffa/1x',
          },
          {
            name: 'YEP ',
            emoji:
              'https://content.brimecdn.com/brime/emote/608c9405e2e12599035c6f61/1x',
          },
        ],
      },
      {
        autoHide: true,
      },
    )

    picker.on('emoji', selection => {
      if (selection.url) {
        this.old = this.message
        this.message = this.old + selection.name
      } else {
        this.old = this.message
        this.message = this.old + selection.emoji
      }
    })

    document
      .querySelector('.emoji-picker-trigger')
      .addEventListener('click', e => picker.togglePicker(e.targetElement))

    const channelData = await window.brime.getChannel(window.location.pathname.substring(1))
    const streamData = await window.brime.getStream(channelData.channel)

    document.title = `${channelData.channel} - Brime Live`

    if (streamData.isLive) {
      streamData.streams.forEach(stream => {
        const res = parseInt(stream.resolution.split('x')[1], 10) // "1280x720" -> "720"

        if (stream.isSource) {
          this.sourceRes = res
        }

        this.resOptions.push(res)
      })
    }

    // Some general use variables now that we have channel and stream data.
    this.followers = channelData.followerCount
    this.title = channelData.title
    this.category = channelData.category
    this.channelId = channelData._id
    this.channel = channelData.channel
    // eslint-disable-next-line
    this.channelOwner = channelData.owners[0];
    this.monetized = channelData.isMonetized
    this.live = streamData.isLive
    this.streamData = streamData
    this.channelData = channelData
    this.channelVods = await window.brime.getVodsForChannel(channelData._id)
    this.channelClips = await window.brime.getClipsForChannel(channelData._id)
    this.isChannelOwner = this.userData ? channelData.owners.includes(this.userData._id) : false
    this.musicData = this.streamData.music

    // Check if we're logged in, and if so then we check if we're following and/or subscribed.
    if (window.brime.getRefreshToken()) {
      window.brime.isFollowing(this.channelId).then(following => {
        this.following = following
      })
      window.brime.isSubscribed(this.channelId).then(data => {
        this.isSubscribed = data
      })
    }

    // Follow count updater
    const updateFollowerCount = async () => {
      const updatedChannel = await window.brime.getChannel(this.channelId)

      document.getElementById('followCount').innerText = updatedChannel.followerCount
    }

    updateFollowerCount()
    this.followCountUpdate = setInterval(updateFollowerCount, 15000)

    // Moderator detection
    if (this.userData) {
      this.isModerator = channelData.moderators.includes(this.userData._id)
        || channelData.owners.includes(this.userData._id)
    }

    // IDFK Geeken had it.
    switch (this.channel) {
      case 'TWG2022':
      case 'Zach':
      case 'Geeken':
        this.verified = true
        break

      case 'BRIME':
        this.verified = true
        this.category = { name: 'Community Update', slug: 'community-update' }
        break

      default:
        break
    }

    // Ably init
    const clientId = localStorage.getItem('accessToken')
      ? userData.username
      : getUUID()
    this.realtime = new Ably.Realtime({
      key: 'TTKZpg.6NfkQA:gAXhL8RYoA8iQ2o7',
      clientId,
    })
    this.channelChat = this.realtime.channels.get(
      `${this.channelId}/chat`,
    )
    this.realtime.connection.on('connected', () => {})
    this.channelChat.presence.enter(null, () => {
      this.updateViewCount()
      this.viewsUpdater = setInterval(this.updateViewCount, 15000)
    })

    // Chat
    function escapeHtml(unsafe) {
      return unsafe
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
    }

    function replaceURLs(message) {
      if (message) {
        const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi

        return message.replace(urlRegex, url => {
          let hyperlink = url
          if (!hyperlink.match('^https?://')) {
            hyperlink = `http://${hyperlink}`
          }
          return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`
        })
      }

      return null
    }

    const addChatMessage = (type, obj, isRewind) => {
      switch (type) {
        case 'raid_target': {
          if (!isRewind) {
            const targetChanel = obj.targetChannel

            // Send them on their way
            this.$router.push({ path: `/${targetChanel}` })
          }
          break
        }

        case 'music': {
          this.musicData = obj
          this.updateMusicWidget()
          break
        }

        case 'delete': {
          const element = document.getElementById(obj.messageID)

          // eslint-disable-next-line
          element?.remove();
          break
        }

        case 'chat': {
          let badgeHtml = ''
          let messageContents = escapeHtml(obj.message)

          messageContents = messageContents.replace('https://', '')
          messageContents = messageContents.replace('http://', '')
          messageContents = replaceURLs(messageContents)

          Object.entries(obj.emotes ?? {}).forEach(([key, value]) => {
            const html = `<img alt="${key}" title="${key}" src="https://content.brimecdn.com/brime/emote/${value._id}/1x" />`
            messageContents = messageContents.split(key).join(html)
          })

          obj.sender.badges.forEach(badge => {
            badgeHtml += `<img class="chatBadge" src="${badge}" style="height: 1.20em; vertical-align: middle;">`
          })

          let msgElem = ''

          const isImportantMessage = ['BRIMEBOT'].includes(obj.sender._id)
          let displayContents = obj.richContents ?? messageContents

          if (this.isModerator && !isImportantMessage) {
            msgElem += `<a title="Delete Message" onclick="brime.deleteMessage('${this.channelId}', '${obj._id}')"><svg xmlns="http://www.w3.org/2000/svg" style="fill: rgba(255, 255, 255, 0.2); margin-left: 0px;" width="16" height="16" viewBox="0 0 24 24"><path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z"/></svg></a>&nbsp;`
          }
          // Replace newlines and tabs
          displayContents = displayContents.replace(/\n/g, '<br>')
          displayContents = displayContents.replace(/\t/g, '    ')

          msgElem += `
            <span style="margin-left: -3px;">${['BRIMEBOT'].includes(obj.sender._id) ? '&nbsp; ' : badgeHtml}</span>
            <span style="color: ${escapeHtml(obj.sender.color)}"><b><a style="font-size: 13px; color: inherit;" href="/${escapeHtml(obj.sender.displayname)}" target="_blank" style="color: ${escapeHtml(obj.sender.color)}">${['BRIMEBOT'].includes(obj.sender._id) ? '' : escapeHtml(obj.sender.displayname)}</a></b></span><span style="font-size: 13px;"> ${displayContents}</span>
          `

          // Add message to chat thread and scroll to bottom
          const ul = document.getElementById('chat-thread')
          const li = document.createElement('li')
          li.id = obj._id
          li.innerHTML = msgElem
          li.setAttribute('data-v-4cb7cfca', '')
          li.setAttribute('class', 'chatLine')
          ul.appendChild(li)
          ul.scrollTop = ul.scrollHeight

          if (isImportantMessage) {
            li.setAttribute('style', 'background-color: rgba(255, 255, 255, 0.1); padding-top: 5px !important; padding-bottom: 5px !important; border-left: 4px solid #7367f0 ;')
          }
          const isUser = [`${userData.username}`].includes(obj.message.toLowerCase())
          const isUserAt = [`@${userData.username}`].includes(obj.message.toLowerCase())
          if (isUser || isUserAt) {
            li.setAttribute('style', 'background-color: rgba(255, 255, 255, 0.1); padding-top: 5px !important; padding-bottom: 5px !important; border-left: 4px solid #7367f0 ;')
          }
          // chatThread.scrollTop = chatThread.scrollHeight;
          break
        }

        default:
          break
      }
    }

    this.channelChat.subscribe(message => addChatMessage(message.name, JSON.parse(message.data), false))

    // Now it's time for the player.
    await this.loadTransportScripts()

    this.lowLatencyMode = localStorage.getItem('brime_low_latency_mode') !== 'false'

    this.updatePlayerTransport();

    (async () => {
      // Add the chat history
      this.streamData.chatHistory.forEach(data => addChatMessage('chat', data, true))
    })()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)

    if (this.viewsUpdater) {
      clearInterval(this.viewsUpdater)
    }

    if (this.followCountUpdate) {
      clearInterval(this.followCountUpdate)
    }

    this.killActiveTransport()
    this.realtime.close()
    this.player.destroy()
  },
  methods: {
  /* eslint-disable */
    updateChatters() {
      const vm = this
      this.channelChat.presence.get((err, members) => {
        let i
        const users = []
        const vm = this
        for (const index in members) {
          let test = vm.suggestions.includes(members[index].clientId)
          if (test === false){
          vm.suggestions.push(`${members[index].clientId}`)
          }
        }
      })
    },
    updateViewCount() {
      this.channelChat.presence.get((err, members) => {
        const keys = []
        const lmems = members.filter(e => {
          if (keys.includes(e.clientId)) return false
          keys.push(e.clientId)
          return true
        })
        const msg = lmems.length === 1
          ? ` ${lmems.length} Viewer`
          : ` ${lmems.length} Viewers`
        document.getElementById('viewcount').innerText = msg
      })
    },
    clearPlaceholder() {
      this.autoCompPlaceholder = ''
    },
    fillAutocomplete() {
      this.message += this.autoCompPlaceholder
      this.autoCompPlaceholder = ''
    },
    autoSuggest() {
      this.updateChatters()
      const input = this.message
      this.autoCompPlaceholder = ''
      const self = this

      if (input.includes('@')) {
        const index = input.indexOf('@')
        if (input[index + 1]) {
          const inputToAutocomplete = input.substring(index + 1)
          let flag = false
          this.suggestions.forEach((suggestion, index) => {
            if (flag) return
            if (suggestion.indexOf(inputToAutocomplete) == 0) {
              self.autoCompPlaceholder += suggestion.substring(inputToAutocomplete.length)
              flag = true
            }
          })
        }
      }
    },
    updateMusicWidget() {
      if (this.musicData && this.musicData.state === 'MUSIC_PLAYING') {
        document.querySelector('#musicButton').style.display = 'unset'
        document.querySelector('#musicButton').innerHTML = `
            <svg style="fill: none !important; display: inline !important;" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-music"><path d="M9 18V5l12-2v13"></path><circle cx="6" cy="18" r="3"></circle><circle cx="18" cy="16" r="3"></circle></svg> ${this.musicData.metadata.music[0].title} - ${this.musicData.metadata.music[0].artists[0].name}
            <span class="plyr__sr-only">Clip</span>
          `
      } else {
        document.querySelector('#musicButton').style.display = 'none'
      }
    },
    async loadTransportScripts() {
      const promise1 = new Promise(resolve => {
        const script = document.createElement('script')
        script.src = 'https://cdn.jsdelivr.net/npm/hls.js@latest'
        script.addEventListener('load', resolve)
        document.head.append(script)
      })

      const promise2 = new Promise(resolve => {
        const script = document.createElement('script')
        script.src = 'https://unpkg.com/plyr@3'
        script.addEventListener('load', resolve)
        document.head.append(script)
      })

      await Promise.all([promise1, promise2])
    },

    setLowLatencyMode(enabled) {
      localStorage.setItem('brime_low_latency_mode', enabled)
      this.lowLatencyMode = enabled

      // console.log('Low latency mode switched to', enabled, ', switching transports.')
      this.updatePlayerTransport()

      // The video element's source will change, so it won't auto play.
      document.querySelector('#stream-video').play()
    },

    initPlyr(qualityCallback) {
      if (!this.player) {
        this.player = new window.Plyr('#stream-video', {
          invertTime: false,
          toggleInvert: false,
          controls: [
            this.live ? 'play-large' : '', // The large play button in the center
            // 'rewind',
            'play',
            // 'fast-forward',
            // 'progress',
            'mute',
            'volume',
            'current-time',
            'settings',
            'pip',
            'airplay',
            'fullscreen',
          ],
          settings: [
            'quality',
            // 'speed',
          ],
          quality: {
            default: this.sourceRes,
            options: this.resOptions,
            // this ensures Plyr to use Hls to update quality level
            forced: true,
            onChange: qualityCallback,
          },
        })

        const menusElement = document.querySelector('.brimePlayer .plyr__controls .plyr__menu__container div')
        const settingsHomeElement = menusElement.firstElementChild.firstElementChild

        const toggleLowLatencyButton = document.createElement('button')
        toggleLowLatencyButton.innerHTML = '<span>Low Latency<span class="plyr__menu__value">Enabled</span></span>'
        toggleLowLatencyButton.classList = 'plyr__control plyr__control--forward'
        toggleLowLatencyButton.type = 'button'
        toggleLowLatencyButton.setAttribute('role', 'menuitem')
        toggleLowLatencyButton.setAttribute('data-plyr', 'settings')
        toggleLowLatencyButton.setAttribute('aria-haspopup', true)
        settingsHomeElement.appendChild(toggleLowLatencyButton)

        const toggleLowLatencyOpt = document.createElement('div')
        toggleLowLatencyOpt.innerHTML = `
          <button type="button" class="plyr__control plyr__control--back">
            <span aria-hidden="true">Low Latency Mode</span>
            <span class="plyr__sr-only">Go back to previous menu</span>
          </button>
          <div role="menu">
            <button data-plyr="lowlatency" type="button" role="menuitemradio" class="plyr__control" aria-checked="false" value="Enabled"><span>Enabled</span></button>
            <button data-plyr="lowlatency" type="button" role="menuitemradio" class="plyr__control" aria-checked="false" value="Disabled"><span>Disabled</span></button>
          </div>
        `
        toggleLowLatencyOpt.id = 'plyr-settings-lowlatency'
        toggleLowLatencyOpt.setAttribute('hidden', true)
        menusElement.appendChild(toggleLowLatencyOpt)

        const menuValue = toggleLowLatencyButton.querySelector('.plyr__menu__value')
        const lowLatencyEnable = toggleLowLatencyOpt.querySelector("[value='Enabled'")
        const lowLatencyDisable = toggleLowLatencyOpt.querySelector("[value='Disabled'")

        const updateCheckedButtons = () => {
          lowLatencyEnable.setAttribute('aria-checked', this.lowLatencyMode)
          lowLatencyDisable.setAttribute('aria-checked', !this.lowLatencyMode)

          menuValue.innerText = this.lowLatencyMode ? 'Enabled' : 'Disabled'
        }

        const navigateToHomeSettings = () => {
          settingsHomeElement.removeAttribute('hidden')
          toggleLowLatencyOpt.setAttribute('hidden', true)
        }

        // Go back to the home settings
        toggleLowLatencyOpt
          .querySelector('.plyr__control--back')
          .addEventListener('click', navigateToHomeSettings)

        // Radio buttons
        lowLatencyEnable
          .addEventListener('click', () => {
            this.setLowLatencyMode(true)
            updateCheckedButtons()
            navigateToHomeSettings()
          })

        lowLatencyDisable
          .addEventListener('click', () => {
            this.setLowLatencyMode(false)
            updateCheckedButtons()
            navigateToHomeSettings()
          })

        // Pop the menu out on click
        toggleLowLatencyButton.addEventListener('click', () => {
          toggleLowLatencyOpt.removeAttribute('hidden')
          settingsHomeElement.setAttribute('hidden', true)
        })

        updateCheckedButtons() // Show the current value.

        const controlsMenu = document.querySelector('.brimePlayer .plyr__controls')

        if (this.live) {
          // Add the clip button to the main button list
          const clipButton = document.createElement('button')

          clipButton.classList = 'plyr__controls__item plyr__control'
          clipButton.setAttribute('type', 'button')
          clipButton.setAttribute('data-plyr', 'clip')
          clipButton.innerHTML = `
            <svg style="fill: none !important;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-scissors"><circle cx="6" cy="6" r="3"></circle><circle cx="6" cy="18" r="3"></circle><line x1="20" y1="4" x2="8.12" y2="15.88"></line><line x1="14.47" y1="14.48" x2="20" y2="20"></line><line x1="8.12" y1="8.12" x2="12" y2="12"></line></svg>
            <span class="plyr__sr-only">Clip</span>
          `
          clipButton.addEventListener('click', () => {
          // Click the button (hidden above)
            document.querySelector('#clipItButton').click()
          })

          controlsMenu
            .insertBefore(clipButton, controlsMenu.querySelector("[data-plyr='pip']"))

          if (this.musicData && this.musicData.state === 'MUSIC_PLAYING') {
          // Add Music Button
            const musicButton = document.createElement('button')

            musicButton.id = 'musicButton'
            musicButton.classList = 'plyr__controls__item plyr__control'
            musicButton.setAttribute('type', 'button')
            musicButton.setAttribute('data-plyr', 'clip')
            musicButton.innerHTML = `
            <svg style="fill: none !important; display: inline !important;" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-music"><path d="M9 18V5l12-2v13"></path><circle cx="6" cy="18" r="3"></circle><circle cx="18" cy="16" r="3"></circle></svg> ${this.musicData.metadata.music[0].title} - ${this.musicData.metadata.music[0].artists[0].name}
            <span class="plyr__sr-only">Clip</span>
          `
            musicButton.addEventListener('click', () => {
              // Click the button (hidden above)
              window.open(
                `https://open.spotify.com/track/${this.musicData.metadata.music[0].external_metadata.spotify.track.id}`, '_blank',
              )
            })

            controlsMenu
              .insertBefore(musicButton, controlsMenu.querySelector("[data-plyr='pip']"))

            // this.updateMusicWidget()
          }
        }
        // Finally, click play.
        if (this.live) {
          this.player.play()
        }
      }
    },
    killActiveTransport() {
      if (this.sldpPlayer) this.sldpPlayer.destroy()
      if (this.hls) this.hls.destroy()
    },
    async updatePlayerTransport() {
      const videoElement = document.querySelector('#stream-video')

      this.killActiveTransport()

      // Hide the clip button
      // eslint-disable-next-line
      document
        .querySelector('#clipItButton')
        ?.setAttribute('hidden', true)
      this.iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);
      console.log (this.iOSDevice)
      if (this.lowLatencyMode) { // SLDP check.
        this.brimeLLSuppported = true

        const poster = this.live ? `https://content.brimecdn.com/live/${this.channelData._id}/thumbnail.jpg` : this.channelData.offlineImageUrl

        this.sldpPlayer = window.brimePlayer.init({
          container: 'stream-player',
          video_element: 'stream-video',
          stream_url: `wss://ws-video.brimecdn.com/live_abr/${this.channelData._id}`,
          splash_screen: poster,
          autoplay: true,
          offset: 2,
          buffering: 500,
          reconnects: 20,
          adaptive_bitrate: false,
          height: 'parent',
          width: 'parent',
          initial_resolution: `${this.sourceRes}p`,
          key_frame_alignment: true,
        })

        const qualityCallback = quality => {
          this.sldpPlayer.changeRendition(`${quality}p`)
        }

        this.initPlyr(qualityCallback)

        this.player.on('pause', () => {
          this.sldpPlayer.pause()
        })

        this.player.on('play', () => {
          this.sldpPlayer.play()
        })
        this.player.poster = poster
        this.sldpPlayer.setCallbacks({})
      } else {
        this.brimeLLSuppported = false

        const source = `https://edge.brimelive.com/live_abr/${this.channelData._id}/playlist.m3u8`

        if (window.Hls.isSupported()) {
          this.hls = new window.Hls()

          const hlsLoadPromise = new Promise(resolve => {
            this.hls.on(window.Hls.Events.MANIFEST_PARSED, resolve)
          })

          this.hls.loadSource(source)
          this.hls.attachMedia(videoElement)

          await hlsLoadPromise

          const changeQuality = quality => {
            // console.log(this.hls.levels)
            this.hls.levels.forEach((level, levelIndex) => {
              if (level.height === quality) {
                // console.log('Found quality match with ', quality)
                this.hls.currentLevel = levelIndex
              }
            })
          }

          this.initPlyr(changeQuality)
        } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
          // Very edgecase-y.
          videoElement.src = source

          // Unhide the clip button, since this is most-likely
          // a mobile enviroment that will hide the player and
          // show a platform-specific one.
          // eslint-disable-next-line
          document
            .querySelector('#clipItButton')
            ?.removeAttribute('hidden')

          this.initPlyr(() => {})
        }
      }
    },

    scrollPastVideo() {
      this.player.pause()
      const offset = 80 // sticky nav height
      const el = document.getElementById('viewcount')
      window.scroll({
        top: el.offsetTop - offset,
        left: 0,
        behavior: 'smooth',
      })
    },

    sendRaid() {
      window.brime.raidChannel(this.channelId)
    },

    // Dates and Times
    getDate(clipEpoch) {
      const d = new Date(clipEpoch)
      return d.toLocaleString()
    },
    getDuration(start, end) {
      if (end === -1) return 'LIVE'
      return new Date(end - start).toISOString().substr(11, 8)
    },
    getAgo(start) {
      let ago = Math.floor((new Date().getTime() - start) / 1000 / 60)
      if (ago < 60) {
        return `${ago}min ago`
      }
      ago = Math.floor(ago / 60)
      if (ago < 24) {
        return `${ago}h ago`
      }
      return `${Math.floor(ago / 24)} Days ago`
    },
    getDurationClip(start, end) {
      if (end === -1) return 'live'
      const timeStamp = new Date(end * 1000 - start * 1000)
        .toISOString()
        .substr(11, 8)
      return timeStamp.substr(3)
    },
    getAgoClip(start) {
      const secondsSinceEpoch = Math.floor(new Date().getTime())
      let ago = Math.floor((secondsSinceEpoch / 1000 - start) / 60)
      if (ago < 60) {
        return `${ago}min ago`
      }
      ago = Math.floor(ago / 60)
      if (ago < 24) {
        return `${ago}h ago`
      }
      return `${Math.floor(ago / 24)} Days ago`
    },

    // Chargebee, FeelsBrimeProMan
    openCheckout() {
      this.cbInstance.openCheckout({
        hostedPage: () => {
          const messageBody = {
            plan_id: 'tier-1',
            channel_id: this.channelId,
            channel: this.channel,
          }
          return axios
            .post(
              `${window.brime.apiBase}/internal/billing/generate_checkout_new_url?client_id=${window.brime.clientId}`,
              messageBody,
            )
            .then(response => response.data.data.hosted_page)
        },
      })
    },

    // Clips
    killClipPlayer() {
      // eslint-disable-next-line
      plyr.player.destroyPlayer();
    },
    sendInfo(item) {
      this.selectedClip = item
    },
    async clipIt() {
      this.clipGenerating = true
      await window.brime.createClip(this.channel).then(data => {
        this.clipVideoUrl = data.clipVideoUrl
        this.clipID = data._id
        this.clipDone = true
        setTimeout((this.clipGenerating = false), 3000)
      })
    },
    async deleteClip(clipID) {
      await window.brime.deleteClip(clipID)

      this.$bvToast.toast('Clip Deleted', {
        title: 'Success',
        variant: 'success',
        solid: true,
      })
    },

    // Follows
    async follow() {
      this.following = true
      this.followers += 1
      await window.brime.follow(this.channelId)
    },
    async unfollow() {
      this.following = false
      this.followers -= 1
      await window.brime.unfollow(this.channelId)
    },

    // Chat
    focusInput() {
      this.$refs.message22.focus()
    },
    async sendMessage() {
      const response = await window.brime.sendChatMessage(
        this.messageToSend,
        this.channelId,
      )

      if (response.message) {
        // Add message to chat thread and scroll to bottom
        const ul = document.getElementById('chat-thread')
        const li = document.createElement('li')

        li.innerText = response.message
        li.setAttribute('data-v-4cb7cfca', '')
        li.setAttribute('class', 'chatLine')
        li.style.color = '#b4b7bd'

        ul.appendChild(li)
        ul.scrollTop = ul.scrollHeight
      }

      this.message = ''
    },
  },
}
</script>

<style>
.dark-layout .nav-tabs .nav-item .nav-link.active {
  background-color: unset !important;
}
.plyr {
  --plyr-color-main: linear-gradient(90deg, #833ab4, #fd1d1d) !important;
}
.chatBadge:not(:first-child) {
  padding-left: 2px;
}
.loadingClip {
  width: 100%;
}
.clipVideo {
  width: 100%;
}
.clipButton {
  margin-top: 5px;
  margin-right: 30px;
}
.subButton {
  margin-top: 5px;
  margin-right: 0px;
}
.followers {
  padding-left: 10px;
  font-size: 14px;
}
.streamTitle {
  padding-top: 5px;
  font-size: 14px;
}
.streamCategory {
  margin-top: -20px;
  margin-left: 55px;
  font-size: 14px;
}

.chat-thread {
  padding-left: 5px;
  padding-top: 7px;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-thread li {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 1px 5px 1px 10px;
  margin: 0 0 10px 0;
  font: 16px/20px;
  width: 100%;
}

/* Chat - Speech Bubble Arrow */
.chat-thread li:before {
  position: absolute;
  top: 5px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid rgba(25, 147, 147, 0.2);
}

.chat-thread li {
  animation: show-chat-odd 0.15s 1 ease-in;
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  float: left;
  margin-left: 0px;
  color: #fff;
  word-wrap: break-word;
}

.chat-thread li:after {
  border-right: 15px solid transparent;
  right: -15px;
}

.chat-window {
  position: fixed;
  bottom: 12px;
  right: 0;
  width: 100%;
}
.chatInput {
  margin-left: 2.5%;
  margin-right: 2.5%;
  width: 96%;
  bottom: 0;
  box-shadow: none !important;
}

.chat-window-message {
  width: 100%;
  height: 48px;
  font: 32px/48px "Noto Sans", sans-serif;
  background: none;
  color: #ffff;
  border: 0;
  border-bottom: 1px solid rgba(25, 147, 147, 0.2);
  outline: none;
  bottom: 0;
  display: fixed;
}

/* Small screens */
@media all and (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
  .chat-thread {
    width: 90%;
    height: 230px;
  }
  .chat-window {
    left: 5%;
    width: 90%;
  }
  #stream-video {
    min-height: unset !important;
    height: unset !important;
  }
  .column1 {
    width: 80%;
    margin-left: -15px;
    height: unset !important;
    margin-top: -10px;
  }
  #channelName {
    font-size: 10px !important;
  }
  .tabSection {
    display: none;
  }
  .actionButtons {
    right: -15px !important;
    position: fixed;
  }
  #channel {
    font-size: 14px !important;
    margin-top: 15px !important;
    margin-left: 2px !important;
  }
  .viewcount {
    font-size: 12px !important;
  }
  .streamCategory {
    margin-top: 2px !important;
    margin-left: 0 !important;
  }
}
/* Medium and large screens */
@media all and (min-width: 768px) {
  .chat-thread {
    width: 100%;
    height: 85vh;
  }
  #stream-video {
    /* override other styles to make responsive */
    width: 100% !important;
    min-height: 84vh !important;
  }
  .chat-window {
    width: 100%;
  }
}
@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
</style>
<style scoped>
.card:hover {
  transform: translateY(-3px);
  box-shadow: 0px 0px 20px rgba(115, 103, 240, 1);
}

.card img {
  transition: all 0.2s;
}
.card {
  box-shadow: none;
  padding: 0.5rem;
}
.vod__category {
  color: #7367f0 !important;
}
.card-body {
  padding: unset !important;
}
.card {
  background-color: unset !important;
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-4px);
}

.card:hover .vod__image {
  opacity: 1;
}

.vod__title {
  margin-top: 0.5rem;
  font-weight: bolder;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vod__image {
  position: relative;
  opacity: 1;
  transition: opacity 0.2s;
}

.vod__duration {
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 0px 6px;
  background: #7367f0;
  color: white;
  border-radius: 3px;
  font-size: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.vod__category,
.vod__date {
  font-size: 0.9em;
  color: #fff;
}

.tabSection {
  margin-top: 8vh;
  margin-left: 1vw;
  margin-right: 1vw;
}
.vodSection {
  margin-top: 5vh;
}
#stream-video {
  /* override other styles to make responsive */
  width: 100% !important;
}
.btn {
  margin-left: 10px !important;
}
.disabled {
  margin-left: 0px !important;
}
.actionButtons {
  margin-top: -50px;
  margin-right: 20px;
}
.creatorMenu {
  margin-top: 10px;
}
#doge-canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9001;
}
.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
#channel {
  margin-left: 15px;
  margin-top: 15px;
  top: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
}
#channel img {
  border-radius: 50%;
  vertical-align: middle;
}
button {
  margin-left: 20px;
}
.column1 {
  width: 80%;
  margin-left: -15px;
  height: 80vh;
  margin-top: -5px;
}
.column {
  width: calc(20% + 0.5rem);
  flex: 1;
  background: #111727;
  margin-right: -12px;
  padding-bottom: 2vh;
  height: 100%;
}
.row {
  margin-top: -25px !important;
}
.channel__row {
  flex-wrap: nowrap;
}

#player {
  flex-shrink: 0;
}
.content-body {
  height: 100% !important;
}

@media screen and (min-width: 769px) {
  .chat__column {
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    top: var(--headerHeight);
    height: calc(100% - var(--headerHeight));
    padding-bottom: 1rem;
  }

  .chat-thread {
    flex: 1;
  }

  #chat-form {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 768px) {
  .column1 {
    width: 100%;
    margin-left: 0px;
    margin: 0;
    padding: 0;
  }

  .channel__row {
    flex-wrap: wrap;
  }

  .chat__column {
    width: 100%;
    flex-shrink: 0;
    margin: 0;
  }

  .chat__column form {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .chat-thread {
    width: 100%;
  }

  .content {
    padding: 0px;
  }

  #player {
    max-height: 80%;
  }
}
</style>
